
<template>
  <div class="analysis-widget">
    <ChartDiagram
      v-if="probeFinancialData || referenceFinancialData"
      :chartData="chartData"
      :difference="difference"
      :showLabelTexts="true"
      :showMediaSpendingsLabels="false"
      :subtitle="chartSubtitle"/>
    <div v-if="!probeFinancialData && !referenceFinancialData">No data available.</div>
    <LevelMeter v-if="historicTurnoverEnabled" :label="deviation.label" :percentage="deviation.percentage"/>
  </div>
</template>

<script>
import ChartDiagram from "@/components/chart/ChartDiagram.vue";
import LevelMeter from "@/components/chart/LevelMeter.vue";
import AnalysisMixin from '@/components/mixins/AnalysisMixin.vue';

export default {
  name: "AnalysisPage",
  mixins: [AnalysisMixin],
  components: {
    ChartDiagram,
    LevelMeter
  },
  methods: {
    reload() {
      let favId = this.favoritePredictionId
      this.fetchReferenceData({ historicYear : this.$store.getters["config/lastKnownYear"] })
      if (favId) {
        this.$store.commit("analysis/datasetIdForKeyAndType", {
          type: "probe",
          key: this.getDatasetKey('probe'),
          id: favId
        })
        this.fetchProbeData()
      }
    }
  },
  computed: {
    historicTurnoverEnabled() {
      return this.$store.state.config.segments.find(cfg => cfg.key == this.currentSegment)?.historic_turnover_enabled
    },
    predictions() {
      return this.$store.state.predictions.predictions
    },
    deviation() {
      var deviation = {
        label: "0%",
        percentage: 0
      }
      if (this.probeFinancialData.meta) {
        let segment = this.$store.getters["ui/currentSegment"]
        deviation.label = this.probeFinancialData.meta.segments[segment].deviation
        deviation.percentage = this.probeFinancialData.meta.segments[segment].deviation_percent
      }
      return deviation
    },
    turnoverSums() {
      return this.calculateTurnoverSums()
    },
    referenceTotalValue() {
      return this.turnoverSums.sumReference
    },
    probeTotalValue() {
      return this.turnoverSums.sumProbe
    },
    difference() {
      return this.probeTotalValue - this.referenceTotalValue
    },
    currentSegment() {
      return this.$store.getters["ui/currentSegment"]
    },
    favoritePredictionId() {
      return this.$store.getters["predictions/favoritePredictionId"]
    }
  },
  watch: {
    predictions() {
      this.probeDataId = this.$store.getters["predictions/favoritePredictionId"]
      this.fetchProbeData()
    },
    favoritePredictionId() {
      this.reload()
    }
  }
};
</script>

<style scoped>
.analysis-widget {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.chart {
  flex-grow: 1;
}
</style>
