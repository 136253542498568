<template>
  <div class="analysis-page flex vertical full-height">
    <div class="scrollable-container main">
      <WidgetRow gridTemplateColumns="1fr" flexGrow="1">
        <WidgetContainer>
          <ChartDiagram
            v-if="probeFinancialData || referenceFinancialData"
            :chartData="chartData"
            :zoomRange="zoomRange"
            :showLabelTexts="true"
            :subtitle="chartSubtitle"/>
          <div v-if="!probeFinancialData && !referenceFinancialData">Keine Daten zum Anzeigen vorhanden.</div>
        </WidgetContainer>
      </WidgetRow>
      <WidgetRow gridTemplateColumns="1fr" flexGrow="0">
        <WidgetContainer>
          <MediaplanComparison :probeFinancialData="probeFinancialData" :referenceFinancialData="referenceFinancialData" />
        </WidgetContainer>
      </WidgetRow>
      <WidgetRow v-if="$store.state.admin.isDevModeEnabled" gridTemplateColumns="1fr" flexGrow="0">
        <WidgetContainer>
          <json-viewer :value="jsonData"></json-viewer>
        </WidgetContainer>
      </WidgetRow>
    </div>
  </div>
</template>

<script>
import WidgetContainer from "../components/widgets/WidgetContainer.vue";
import MediaplanComparison from "../components/widgets/MediaplanComparison.vue";
import WidgetRow from "../components/widgets/WidgetRow.vue";
import ChartDiagram from "../components/chart/ChartDiagram.vue";
import AnalysisMixin from '@/components/mixins/AnalysisMixin.vue';
import JsonViewer from 'vue-json-viewer'
import useUI from '@/js/useUI.js'

export default {
  name: "AnalysisPage",
  mixins: [AnalysisMixin],
  components: {
    WidgetContainer,
    MediaplanComparison,
    WidgetRow,
    ChartDiagram,
    JsonViewer
  },
  created() {
    this.showChannels = true
  },
  computed: {
    jsonData() {
      return {
        referenceFinancialData: this.$store.getters["analysis/referenceFinancialData"],
        probeFinancialData: this.$store.getters["analysis/probeFinancialData"],
        chartData: this.chartData,
      }
    },
    currentSegment() {
      return this.$store.getters["ui/currentSegment"]
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {

      let referenceDataId = vm.referenceDataId || -1
      let probeDataId = vm.probeDataId || vm.$store.getters["predictions/predictionOptions"].map( o => o.value)?.pop()

      if (to.query.reference) {
        referenceDataId = to.query.reference == "none" ? null : to.query.reference
      }

      if (to.query.probe) {
        probeDataId = to.query.probe == "none" ? null : to.query.probe
      }

      vm.probeDataId = probeDataId
      vm.referenceDataId = referenceDataId

      useUI(vm.$store).blockUI({ loading: true, complete: false, chart: true })

      Promise.all([
        new Promise(resolve => { vm.fetchReferenceData({ blockUI: false }).then( () => resolve()) }),
        new Promise(resolve => { vm.fetchProbeData({ blockUI: false }).then( () => resolve()) }),
      ]).finally(() => {
        vm.$store.commit("ui/isFetchingChartData", false)
        useUI(vm.$store).unblockUI()
      })

    })
  },
};
</script>

<style scoped>
.analysis-main {
    padding: 20px 70px 0 80px;
}
</style>
