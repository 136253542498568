import axios from "../api"

const initialState = {
  brands: [],
  channels: [],
  years: [],
  status: [],
  features: {},
  scenarioGroups: [],
  segments: [],
  weeks: [],
  labels: [],
  granularities: [],
  currency: "",
  currentUser: {},
  error: null,
}

export const config = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    brands(state, brands) {
      state.brands = brands
    },
    channels(state, channels) {
      state.channels = channels
    },
    years(state, years) {
      state.years = years
    },
    labels(state, labels) {
      state.labels = labels
    },
    granularities(state, granularities) {
      state.granularities = granularities
    },
    status(state, status) {
      state.status = status
    },
    features(state, features) {
      state.features = features
    },
    scenarioGroups(state, scenarioGroups) {
      state.scenarioGroups = scenarioGroups
    },
    segments(state, segments) {
      state.segments = segments
    },
    weeks(state, weeks) {
      state.weeks = weeks
    },
    currency(state, currency) {
      state.currency = currency
    },
    currentUser(state, currentUser) {
      state.currentUser = currentUser
    },
    error(state, error) {
      state.error = error
    }
  },
  getters: {
    scenarioOptions(state) {
      let groupMap = {}
      state.scenarioGroups.forEach( group => {
        groupMap[group.key] = group.scenarios.map( s=> {
          return {
            label: s.label,
            value: s.key
          }
        })
      })
      return groupMap
    },
    lastKnownYear(state) {
      return state.years?.known_data?.slice().pop()?.key
    },
    knownDataYearOptions(state) {
      if (state.years && state.years.known_data) {
        return state.years.known_data.map( year => {
          return {
            label: year.label_year_numbers,
            value: year.key
          }
        })
      }
      return []
    },
    predictionYearOptions(state) {
      if (state.years && state.years.prediction) {
        return state.years.prediction.map( year => {
          return {
            label: year.label_year_numbers,
            value: year.key
          }
        })
      }
      return []
    },
    segmentOptions(state) {
      if (state.segments) {
        return state.segments.map( segment => {
          return {
            value: segment.key,
            label: segment.label,
          }
        })
      }
      return []
    },
    weeks(state) {
      return state.weeks
    },
    calendarWeeks(state) {
      return state.weeks.map( w => w.cw )
    },
    labels(state) {
      return state.labels
    },
    granularities(state) {
      return state.granularities
    },
    currentUser(state) {
      return state.currentUser
    },
  },
  actions: {
    fetchStatus({ commit, rootGetters }) {
      return axios.get("/status", { headers: rootGetters["user/authHeader"] })
      .then((response) => {
        commit("status", response.data.data)
      })
      .catch((response) => {
        commit("error", response.message)
        throw(new Error("Failed fetching status"))
      })
    },
    fetchBrands({ commit, rootGetters }) {
      return axios.get("/brands", { headers: rootGetters["user/authHeader"] })
      .then((response) => {
        commit("brands", response.data.data)
      })
      .catch((response) => {
        commit("error", response.message)
        throw(new Error("Failed fetching brands"))
      })
    },
    fetchChannels() {
      // {{base_url}}/channels
    },
    fetchYears() {
      // {{base_url}}/years
    },
    fetchFullConfig({ commit, rootGetters }) {
      return axios.get("/fullconfig", { headers: rootGetters["user/authHeader"] })
      .then((response) => {
        commit("brands", response.data.data.brands)
        commit("channels", response.data.data.channels)
        commit("years", response.data.data.years)
        commit("features", response.data.data.features)
        commit("currency", response.data.data.currency)
        commit("weeks", response.data.data.week_index_to_cw)
        commit("labels", response.data.data.labels)
        commit("granularities", response.data.data.granularities)
        commit("currentUser", response.data.data.current_user)
        commit("segments", response.data.data.segments)
        // the default segment is the one where s.default is true, otherwise the first segment
        const default_segment = response.data.data.segments.find(
          (segment) => { return segment.default }
          )?.key ?? response.data.data.segments[0].key
        commit("ui/currentSegment", default_segment, { root : true })
      })
      .catch((response) => {
        commit("error", response.message)
        throw(new Error("Failed fetching full config"))
      })
    },
    fetchScenarios({ commit, rootGetters }) {
      return axios.get("/scenarios", { headers: rootGetters["user/authHeader"] })
      .then((response) => {
        commit("scenarioGroups", response.data.data.scenario_groups)
      })
      .catch((response) => {
        commit("error", response.message)
        throw(new Error("Failed fetching scenarios/scenarioGroups"))
      })
    }
  }
};
