<template>
  <div></div>
</template>
<script>

import Util from "@/util.js"
import moment from "moment"
import useUI from '@/js/useUI.js'

export default {
  methods: {
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    dateInfos(item) {
      return {
        start: item.start ? this.formatDate(item.start) : null,
        created: item.created_timestamp ? this.formatDate(item.created_timestamp) : null,
        lastModified: item.last_modified_timestamp ? this.formatDate(item.last_modified_timestamp) : null,
      }
    },
    formatNumber(number) {
      return Util.formatNumber(number)
    },
    formatCurrency(number) {
      return Util.formatCurrency(number, this.$store.state.config.currency)
    },
    download(dispatchPath, id) {
      useUI(this.$store).blockUI({ loading: true });
      this.$store
        .dispatch(dispatchPath, id)
        .then((response) => {
          let json = response.data.data
          let filename = json.filename
          const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          const link = document.createElement("a");
          link.href = `data:${mimetype};base64,${json.xlsxBase64}`;

          link.setAttribute(
            "download",
            filename
          );
          document.body.appendChild(link);
          link.click();
          useUI(this.$store).unblockUI();
        })
        .catch((err) => {
          useUI(this.$store).unblockUI();
          console.error(err);
        });
    },
    lightenColor(hexColor, magnitude) {
      hexColor = hexColor.replace(`#`, ``);
      if (hexColor.length === 6) {
          const decimalColor = parseInt(hexColor, 16);
          let r = (decimalColor >> 16) + magnitude;
          r > 255 && (r = 255);
          r < 0 && (r = 0);
          let g = (decimalColor & 0x0000ff) + magnitude;
          g > 255 && (g = 255);
          g < 0 && (g = 0);
          let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
          b > 255 && (b = 255);
          b < 0 && (b = 0);
          return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
      } else {
          return hexColor;
      }
    },
    hexToRgbA(hex, alpha) {
      var c;
      if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
          c= hex.substring(1).split('');
          if(c.length== 3){
              c= [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c= '0x'+c.join('');
          return `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${alpha})`;
      }
      throw new Error('Bad Hex');
    }
  },
};
</script>
