<template>
  <div class="date-picker">
    <VueDatePickerNext
      v-model:value="dateModel"
      format="YYYY-MM-DD"
      :type="type"
      :placeholder="placeholder"
      :formatter="formatter"
      :lang="langObject"
      :disabled-date="disabledDate"
      :shortcuts="shortcuts"
      @open="this.$emit('open')"
    />
    <div class="date-format-hint">{{ dateFormatHint }}</div>
  </div>
</template>

<script>

import { default as VueDatePickerNext } from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import moment from "moment"

export default {
  name: "DatePicker",
  components: {
    VueDatePickerNext
  },
  props: {
    modelValue: {
      type: [Object, String],
      default() {
        return new Date()
      }
    },
    type: {
      type: String,
      default: "week"
    },
    placeholder: {
      type: String,
      default: "Choose a date..."
    },
    disabledDate: {
      type: Function
    },
    shortcuts: {
      type: Object
    },
    formattedValue: {
      type: String
    },
    dateFormatHint: {
      type: String,
      default: ""
    },
    granularity: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      active: false,
      dateModel: this.modelValue,
      langObject: {
          formatLocale: {
            firstDayOfWeek: 1,
            firstWeekContainsDate: 4,
          },
          yearFormat: 'YYYY',
          monthFormat: 'MM',
          monthBeforeYear: false,
      },
      formatter: {
        getWeek: (date) => {
          return moment(date).isoWeek()
        },
        stringify: (date) => {
          if (this.formattedValue) {
            return this.formattedValue
          }

          let defaultString = "No date selected"

          switch (this.type) {
            case "week":
              return date ? `CW ${moment(date).isoWeek()} ${moment(date).isoWeekYear()}` : defaultString
            case "quarter":
              return date ? `Q ${moment(date).quarter()} ${moment(date).year()}` : defaultString
            case "month":
              return date ? moment(date).format("MMMM Y") : defaultString
            case "year":
              return date ? moment(date).format("Y") : defaultString
            default:
              return date ? moment(date).format('LL') : defaultString
          }
        },
      }
    };
  },
  methods: {
    toggle() {
      this.active = !this.active
    },
    accept() {
      this.active = false
    },
    cancel() {
      this.active = false
    },
  },
  watch: {
    dateModel(newValue) {
      this.$emit("update:modelValue", newValue)
    }
  }
};
</script>

<style scoped>
.date-picker {
  position: relative;
}
.date-format-hint {
  position: absolute;
  top: 44px;
  left: 12px;
  font-size: 12px;
  color: var(--c-greyish);
}

/*
--c-white-grey: #F6F8FC;
 --c-soft-grey: #EDF1FA;
 --c-light-grey: #E6EBF7;
 --c-bright-grey: #E6E8EE;
 --c-medium-grey: #D5D8E2;
 --c-medium-grey-menu-divider: rgba(213, 216, 226, 0.1);
 --c-greyish: #AEB4CB;
 --c-deep-grey: #7882A8;
 --c-dark-grey: #555F84;
 */
</style>

<style>
.mx-input {
  height: 40px;
  border-color: #E4E7ED;
  border-radius: 7px;
  box-shadow: none;
}
.mx-datepicker-popup {
  box-shadow: var(--std-box-shadow);
}
.mx-datepicker-sidebar {
  width: 150px;
}
.mx-datepicker-sidebar+.mx-datepicker-content {
  margin-left: 150px;
}
/*
.mx-btn-shortcut {
  height: 34px;
  border-radius: 7px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 11px;
  padding: 0 20px;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  background: var(--c-button-blue);
  border: 1px solid var(--c-button-blue);
  color: #fff;
  height: 44px;
  margin: 0px 0px 10px 15px;
}
.mx-btn-shortcut:first-child {
  margin-top: 20px;
}
.mx-btn-shortcut:last-child {
  margin-bottom: 0;
}
.mx-btn-shortcut:hover {
  background: var(--c-hover-blue);
  color: #fff;
} */
</style>
