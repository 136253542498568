const initialState = {
  isShowingModal: false,
  isLoading: false,
  isMasterViewBlocked: false,
  isScreenBlocked: false,
  isUIUnblockByUserDisabled: false,
  isSiteMenuShown: false,
  contextMenuState: {
    active: false,
    top: 0,
    left: 0,
    data: null
  },
  vendor: {
    phone: "+49 40 4665 658 0",
    email: "pia-support@simcog.de"
  },
  currentSegment: "",
  isFetchingChartData: true,
  error: null,
  debug: false,
}

export const ui = {
  namespaced: true,
  state: () => { return initialState },
  mutations: {
    isLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    isMasterViewBlocked(state, isMasterViewBlocked) {
      state.isMasterViewBlocked = isMasterViewBlocked
    },
    isScreenBlocked(state, isScreenBlocked) {
      state.isScreenBlocked = isScreenBlocked
    },
    isUIUnblockByUserDisabled(state, isUIUnblockByUserDisabled) {
      state.isUIUnblockByUserDisabled = isUIUnblockByUserDisabled
    },
    contextMenuState(state, menuState) {
      state.contextMenuState = menuState
    },
    contextMenuActive(state, active) {
      let menuState = state.contextMenuState
      menuState.active = active
      state.contextMenuState = menuState
    },
    isSiteMenuShown(state, isSiteMenuShown) {
      state.isSiteMenuShown = isSiteMenuShown
    },
    currentSegment(state, currentSegment) {
      state.currentSegment = currentSegment
    },
    isFetchingChartData(state, isFetchingChartData) {
      state.isFetchingChartData = isFetchingChartData
    },
    error(state, error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        state.error = error.response.data.error
      } else {
        state.error = error
      }
    },
    debug(state, debug) {
      state.debug = debug
    },
  },
  getters: {
    actualYear() {
      return new Date().getFullYear()
    },
    currentSegment(state) {
      return state.currentSegment
    },
    debug(state) {
      return state.debug
    }
  },
  actions: {
    toggleContextMenu({ commit, state }, payload) {
      let el = payload.event.target
      if (el.tagName != 'div') {
        el = el.closest('div')
      }
      var rect = el.getBoundingClientRect();
      let menuEntryHeight = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--context-menu-entry-height'))
      let menuHeight = (payload.menuItems.length + 1) * menuEntryHeight
      let menuTop = (rect.top + rect.height + 10)
      let menuState = {
        top: menuTop + 'px',
        left: rect.left + 'px',
        active: !state.contextMenuState.active,
        data: payload.data,
        menuItems: payload.menuItems
      }
      if (menuTop + menuHeight > window.innerHeight) {
        menuTop -= menuHeight
        menuState.top = menuTop + 'px'
      }
      commit('contextMenuState', menuState)
  },

  },
};
