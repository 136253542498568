import Util from "@/util";
import axios from "../api"

const initialState = {
  mediaplans: [],
  currentMediaplanId: null,
  referenceMediaplanId: 0,
  error: null,
  sortInfo: {
    property: "last_modified_timestamp",
    ascending: false
  }
}

export const mediaplanning = {
  namespaced: true,
  state: () => {
    return initialState
  },
  mutations: {
    mediaplans(state, mediaplans) {
      state.mediaplans = mediaplans
    },
    error(state, error) {
      state.error = error
    },
    currentMediaplanId(state, id) {
      state.currentMediaplanId = id
    },
    referenceMediaplanId(state, id) {
      state.referenceMediaplanId = id
    },
    sortInfo(state, sortInfo) {
      state.sortInfo = {
        property: sortInfo.property,
        ascending: state.sortInfo.property == sortInfo.property ? !state.sortInfo.ascending : sortInfo.ascending || true
      }
    },
  },
  getters: {
    mediaplans(state, getters) {
      return getters.sortedMediaplans(state.sortInfo)
    },
    sortedMediaplans: (state, getters, rootState, rootGetters) => (sortInfo) => {
      let mediaplans = state.mediaplans
      switch (sortInfo.property) {
        case "name": return Util.sortByString(mediaplans, "name", sortInfo.ascending)
        case `segments.${rootGetters["ui/currentSegment"]}.total_value`: return Util.sortByNumber(mediaplans, `segments.${rootGetters["ui/currentSegment"]}.total_value`, sortInfo.ascending)
        case "last_modified_timestamp":
        default:
          return Util.sortByTimestamp(mediaplans, "last_modified_timestamp", sortInfo.ascending)
      }
    },
    mediaplanOptions(state) {
      let mediaplans = state.mediaplans.filter( m => !m.ai_details || m.ai_details.status == "DONE")
      return mediaplans.map( m => {
        return {
          label: m.name,
          value: m.id
        }
      })
    },
    mediaplanById: (state) => (id) => {
      return state.mediaplans.find( m => m.id == id)
    },
    referenceMediaplan(state) {
      return state.mediaplans.find( m => m.id == state.referenceMediaplanId)
    }
  },
  actions: {
    fetchAllPlans({
      commit,
      rootGetters
    }) {
      return axios.get("/mediaplanung", {
          headers: rootGetters["user/authHeader"]
        })
        .then((response) => {
          commit("mediaplans", response.data.data)
        })
        .catch((response) => {
          commit("error", response.message)
          throw (new Error("Failed fetching mediaplans"))
        })
    },
    fetchPlan() {
      //mediaplanung/id
    },
    downloadPlan({ rootGetters }, id) {
      ///mediaplanung/0/download
      ///mediaplanung/1/download?asbase64json
      return axios.get(`/mediaplanung/${id}/download?asbase64json`, {
        headers: rootGetters["user/authHeader"],
        //responseType: 'blob',
      })
    },
    downloadPlanNational({ rootGetters }, id) {
      // like downloadPlan, but with additional "national" option
      return axios.get(`/mediaplanung/${id}/download?asbase64json&national`, {
        headers: rootGetters["user/authHeader"],
      })
    },
    uploadPlan({
      rootGetters
    }, formData) {
      let headers = {...{
        'Content-Type': 'multipart/form-data'
      }, ...rootGetters["user/authHeader"]}

      return axios.post("/mediaplanung/upload", formData, {
        headers: headers
      })
    },
    deletePlan({
      rootGetters
    }, id) {
      return axios.delete(`/mediaplanung/${id}`, {
        headers: rootGetters["user/authHeader"]
      })
    },
    updatePlan({
      rootGetters
    }, payload) {
      return axios.patch(`/mediaplanung/${payload.id}`, {
        name: payload.name
      },
      {
        headers: rootGetters["user/authHeader"]
      })
    },
    fetchTemplateNational({
      rootGetters
    }) {
      return axios.get(`/mediaplanung/template?asbase64json&national`, {
        headers: rootGetters["user/authHeader"],
        //responseType: 'blob',
      })
    },
    fetchTemplateRegional({
      rootGetters
    }) {
      return axios.get(`/mediaplanung/template?asbase64json`, {
        headers: rootGetters["user/authHeader"],
      })
    }
  }
};
