
<template>
  <div class="date-info">
    <div v-if="dateInformation.start"><b>Start:</b> {{ dateInformation.start }}</div>
    <div v-if="dateInformation.created"><b>Created:</b> {{ dateInformation.created }}</div>
    <div v-if="dateInformation.lastModified"><b>Last changed:</b> {{ dateInformation.lastModified }}</div>
  </div>
</template>

<script>

import UiMixin from "@/components/mixins/UiMixin.vue"

export default {
  name: 'DateInfo',
  mixins: [UiMixin],
  props: {
      item: Object
  },
  computed: {
    dateInformation() {
      return this.dateInfos(this.item)
    }
  }
}
</script>

<style scoped>
.list-badge {
  position: absolute;
  /* transform: translate(-8px, -12px); */
}
</style>
